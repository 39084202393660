import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ApisService } from '../services/apis.service';

@Injectable({
    providedIn: 'root'
})
export class AuthClientesGuard implements CanActivate {

    constructor(private authServ: ApisService, private router: Router, private route: ActivatedRoute,) { }

    canActivate(route: ActivatedRouteSnapshot): any {
        // /// You can use this one for better security
        // /// You can use this one for better security
        // /// You can use this one for better security
        return new Promise((resolve, reject) => {
            let access = localStorage.getItem('access');
            let token = localStorage.getItem('adminToken');
            let ciudad = localStorage.getItem('ciudad');
            this.authServ.checkToken(token, access, ciudad).subscribe((user:any) => {

                if (user.Result == 'OK') {
                    resolve(true);
                } else {
                    // localStorage.clear();
                    // this.router.navigate(['/auth/login']);

                    let clienteToken = localStorage.getItem('clienteToken');
                    console.log(clienteToken)

                    this.authServ.checkTokenCliente(clienteToken, ciudad).subscribe((client:any) => {
                        console.log(client);
                        
                        if (client.Result == 'OK') {

                            // this.route.queryParams.subscribe(data => {
                            //     console.log(data);

                            //     if (data && data.id) {  
                            //         if (data.id == client.user.dni) {
                            //             resolve(true);
                            //         } else {
                            //             localStorage.clear();
                            //             this.router.navigate(['/auth/cliente']);    
                            //         }
                            //     } else {
                            //         localStorage.clear();
                            //         this.router.navigate(['/auth/cliente']);
                            //     }
                          
                            // });


                            resolve(true);

                        } else {
                            localStorage.clear();
                            this.router.navigate(['/auth/cliente']);
                        }
                    })
                    


                }
            }, error => {
                console.log(error);
                reject(false);
                localStorage.clear();
                this.router.navigate(['/auth/login']);
            });
    
        })


        // ///// Less Secure but faster
        // const uid = localStorage.getItem('uid');
        // console.log('uid', localStorage.getItem('uid'));
        // if (uid && uid != null && uid !== 'null') {
        //     return true;
        // }
        // this.router.navigate(['/auth/login']);
        // return false;
    }
}
